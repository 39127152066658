import JsBarcode from "jsbarcode";
import Canvas from "canvas";
import QRCode from "qrcode";  // 导入二维码生成库
import imageCtrl from "../controllers/imageCtrl";
import Store from "../store";
import { checkProtectionAuthorityNormal } from "../../src/controllers/protection";
// import { luckysheet } from "../core";
// import { modelHTML } from "../controllers/constant";

async function insertQrcodeSign() {

  // 创建 Canvas 对象
  var { createCanvas } = Canvas;
  var canvas = createCanvas();

  // 设置的二维码参数
  let qrCodeOptions = {
    type: "image/png",  // 指定二维码格式为 PNG
    rendererOpts: {
      quality: 0.3,  // 图片质量
    },
  };
// 生成二维码
const qrCodeDataUrl = await QRCode.toDataURL(canvas,"123456789", qrCodeOptions);

let img = new Image();
img.src = qrCodeDataUrl;

// 添加二维码图片到文档
if (
  !checkProtectionAuthorityNormal(
    Store.currentSheetIndex,
    "editObjects",
    false
  )
) {
  return;
}
let file = {
  src: img.src,
  left: 0,
  top: 0,
  originWidth: img.width,
  originHeight: img.height,
  imageType: "qrCode",  // 标记为二维码类型
};
imageCtrl.addImgItem(file);
}

function insertBarcodeSign() {
  var { createCanvas } = Canvas;
  var canvas = createCanvas();

  // 设置的条形码参数
  let options = {
    text: "123456789",
    displayValue: true,
    fontSize: 12,
    height: 40,
  };
  //987654321 是条形码的携带的信息，和option中的text显示信息要区分
  //options:是条形码的配置信息
  JsBarcode(canvas, "987654321", options);
  let img = new Image();
  let url = canvas.toDataURL("image/png");
  img.src = url;

  if (
    !checkProtectionAuthorityNormal(
      Store.currentSheetIndex,
      "editObjects",
      false
    )
  ) {
    return;
  }
  let file = {
    src: img.src,
    left: 0,
    top: 0,
    originWidth: img.width || options.width,
    originHeight: img.height || options.height,
    imageType: "barCode",
  };
  imageCtrl.addImgItem(file);

  // luckysheet.setluckysheet_has_signimage(true);
}

export { insertQrcodeSign, insertBarcodeSign };
